import React, { useEffect, useState } from 'react';
import ViewWrapperWithCommandBar from '../../Layout/ViewWrapperWithCommandBar';
import Wizard from '../../Wizard/Wizard';
import { DefaultPalette, ICommandBarItemProps, Text } from '@fluentui/react';
import { titleCommandBarButtonStyles, defaultCommandBarButtonStyles } from '../../../styles/button-styles';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../common/constants';
import { DateTime } from 'luxon';
import TimeTaxSupportSystemClient from '../../../system';
import MessageCourier from '../../../lib/MessageCourier';
import { useTranslation } from 'react-i18next';
import SettlementPeriod from './Wizard/SettlementPeriod';
import { SettlementsTable } from './Wizard/SettlementsTable';
import GenerateSettlement from './Wizard/GenerateSettlement';
import { IReportePreliquidacion } from '../../../system/reporting/ReportingSystem';

interface ISettlementsViewProps {
  system: TimeTaxSupportSystemClient;
  courier: MessageCourier;
  changeBlockUI: any;
}

export const DISTRIBUCION_PROYECTOS = 'P';
export const DISTRIBUCION_ACTIVIDADES = 'A';
export const DISTRIBUCION_HIBRIDA = 'H';

const DirectSettlementsView = (props: ISettlementsViewProps) => {
  const COLLABORATOR_KEY = 'colaborador';
  const COMPANY_KEY = 'empresa';

  const { t, i18n } = useTranslation();
  const [canGoNext, setCanGoNext] = useState(false);

  const [showLockSettlement, setShowLockSettlement] = useState<boolean>(false);
  const [settlementMode, setSettlementMode] = useState<string>(DISTRIBUCION_PROYECTOS);
  const [lockSettlementWasClosed, setLockSettlementWasClosed] = useState<boolean>(false);

  const [month, setMonth] = useState<DateTime>(DateTime.fromJSDate(new Date()));
  const [concept, setConcept] = useState<string>(COMPANY_KEY);

  const [companyList, setCompanyList] = useState<IReportePreliquidacion[]>();

  const [generatedSettlementResponse, setGeneratedSettlementResponse] = useState<any>();

  const [lastConfirmatedSettlement, setLastConfirmatedSettlement] = useState<String>();
  const [lastBlocked, setLastBlocked] = useState<Date>();
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false); // Nueva línea

  const navigate = useNavigate();

  const openLockSettlement = () => {
    if (!lockSettlementWasClosed) setShowLockSettlement(true);
  };

  useEffect(() => {
    getLastConfirmatedSettlement();
    getLastBlocked();
  }, []);

  useEffect(() => {
    setLockSettlementWasClosed(false);
  }, [month]);

  useEffect(() => {
    console.log(lastConfirmatedSettlement)
    if (lastConfirmatedSettlement) {
      let _month = month.toJSDate();
      let _date = new Date(_month.getFullYear(), _month.getMonth(), 1);
      let _nMonth = DateTime.fromJSDate(_date);
      setMonth(_nMonth);
      setIsDataLoaded(true); // Datos cargados, actualizar el estado
    }else {
      setIsDataLoaded(true); // Datos cargados, actualizar el estado
    }
  }, [lastConfirmatedSettlement]);


  const getLastConfirmatedSettlement = async () => {
    try {
      const response = await props.system.getSettlementSystem().getLastConfirmedSettlement();
      if (response && response.length > 0) {
        let concatenatedDates = response.map(settlement => {
          const date = new Date(settlement.liq_hasta);
          const yearMonth = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`;
          return yearMonth;
        }).join('|');
        setLastConfirmatedSettlement(concatenatedDates);
      } else {
        setLastConfirmatedSettlement("");
      }
    } catch (error) {
      props.courier.messageError(error);
    }
  };

  const getLastBlocked = async () => {
    props.changeBlockUI(true);
    try {
      const response = await props.system.configuration();
      if (response) {
        const lastBlock = response.getActivitiesBlocked();
        if (lastBlock) {
          setLastBlocked(lastBlock.toJSDate());
        }
      }
    } catch (error) {
      props.courier.messageError(error);
    } finally {
      props.changeBlockUI(false);
    }
  };

  const generateSettlement = async () => {
    if (month) {
      props.changeBlockUI(true);
      try {
        const response = await props.system
            .getSettlementSystem()
            .generateDirectSettlement(month, settlementMode);
        if (response) {
          setGeneratedSettlementResponse(response);
          props.courier.messageSuccess(t('La liquidación fue generada exitosamente'));
        }
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        props.changeBlockUI(false);
      }
    }
  };

  const onCloseDialog = (confirm: boolean) => {
    setShowLockSettlement(false);
    setLockSettlementWasClosed(true);
  };

  const getPreliquidacionReport = async (concept) => {
    if (month) {
      let _month = month.toJSDate();
      let _date = new Date(_month.getFullYear(), _month.getMonth(), 1);
      let _nMonth = DateTime.fromJSDate(_date);
      props.changeBlockUI(true);
      try {
        const response = await props.system
            .getReportingSystem()
            .getPreliquidacionForSettlementReport(_nMonth, concept ? concept : COMPANY_KEY, settlementMode);
        setCompanyList(response);
      } catch (error) {
        props.courier.messageError(error);
      } finally {
        if (lastBlocked && month.toJSDate() > lastBlocked) openLockSettlement();
        props.changeBlockUI(false);
      }
    }
  };

  const periodString = () => {
    return month.toFormat('LL/yyyy');
  };

  const Titles = [t('1. Período'), t('2. Totales a liquidar'), t('3. Generar liquidación')];
  const Descriptions = [
    t('Selecciona el periodo para la próxima liquidación directa.'),
    t('Totales a liquidar en el período ') + periodString(),
    t('Generar liquidación.'),
  ];

  const CompanySelectorData = {
    month: month,
  };

  const setButtonDisability = (can: boolean) => {
    setCanGoNext(can);
  };

  const itemsMenu = React.useMemo(
      (): ICommandBarItemProps[] => [
        {
          key: 'title',
          text: t('Liquidaciones directas'),
          iconProps: {
            iconName: 'TableComputed',
            color: DefaultPalette.themeLighterAlt,
            className: 'text-title-btn',
          },
          buttonStyles: titleCommandBarButtonStyles,
          className: 'text-title-btn',
        },
        {
          key: 'back',
          text: t('Volver'),
          onClick: () => navigate(`${routes.direct_settlement_list}`),
          iconProps: { iconName: 'Back' },
          buttonStyles: defaultCommandBarButtonStyles,
        },
      ],
      [navigate]
  );

  const getDataFromCompanySelectorAssistant = (month: DateTime) => {
    setMonth(month);
  };

  const getDataFromTableAssistant = (concept: string) => {
    setConcept(concept);
  };

  const hasSettlementItems = () => {
    if (companyList && companyList.length > 0) {
      return true;
    } else if (companyList && companyList.length < 1) {
      return false;
    } else {
      return null;
    }
  };

  const Selectors = [
    <SettlementPeriod
        system={props.system}
        courier={props.courier}
        CompanySelectorData={CompanySelectorData}
        getDataFromCompanySelectorAssistant={getDataFromCompanySelectorAssistant}
        setButtonDisability={setButtonDisability}
        companyList={companyList}
        hasItems={hasSettlementItems()}
        month={month}
        getPreliquidacionReport={getPreliquidacionReport}
        openLockSettlement={openLockSettlement}
        COMPANY_KEY={COMPANY_KEY}
        lastSettlement={lastConfirmatedSettlement}
        settlementMode={settlementMode}
        setSettlementMode={(m) => setSettlementMode(m)}
    />,
    <SettlementsTable
        system={props.system}
        courier={props.courier}
        changeBlockUI={props.changeBlockUI}
        getDataFromTableAssistant={getDataFromTableAssistant}
        dataTable={companyList ? companyList : []}
        from={month}
        concept={concept}
        COLLABORATOR_KEY={COLLABORATOR_KEY}
        COMPANY_KEY={COMPANY_KEY}
        getPreliquidacionReport={getPreliquidacionReport}
        settlementMode={settlementMode}
    />,
    <GenerateSettlement
        system={props.system}
        courier={props.courier}
        setButtonDisability={setButtonDisability}
        dataTable={companyList ? companyList : null}
        generateSettlement={generateSettlement}
        generatedSettlementResponse={generatedSettlementResponse}
    />,
  ];

  if (!isDataLoaded) {
    return <div>Cargando...</div>; // Muestra un mensaje de carga mientras los datos se obtienen
  }

  return (
      <ViewWrapperWithCommandBar
          withBorderBottom
          title={''}
          items={itemsMenu}
          overflowButtonStyles={defaultCommandBarButtonStyles}
          variant="highligth"
          titleBody={t('Proyectos')}
      >
        <div className="padding-1">
          <div className="ms-Grid bg-white-h100" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 mt-2 ml-1 defense-file-container-desc">
                <Text className="ml-10px defense-file-title d-block">
                  {t('Asistente para la generación de liquidaciones de servicios (directas)')}
                </Text>
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ml-1"></div>
            </div>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12">
                <Wizard
                    componentsTitlesArray={Titles}
                    componentsDescriptions={Descriptions}
                    componentsArray={Selectors}
                    canGoNext={canGoNext}
                />
              </div>
            </div>
          </div>
        </div>
      </ViewWrapperWithCommandBar>
  );
};

export default DirectSettlementsView;
