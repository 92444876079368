import { DatePicker, IDatePickerProps } from '@fluentui/react';
import { useEffect, useRef, useState } from 'react';
import { DateCustom } from './DateCustom';

interface CustomDatePickerProps {
    strings: IDatePickerProps['strings'];
    label: string;
    value: Date;
    minDate: Date;
    blockedMonths: { [key: number]: number[] };
    onSelectDate: (date: Date | null | undefined) => void;
}

const CustomDatePicker: React.FunctionComponent<CustomDatePickerProps> = ({ strings, label, value, minDate, blockedMonths, onSelectDate }) => {
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const yearObserverRef = useRef<MutationObserver | null>(null);

    const observeYearChanges = () => {
        const holaElement = document.querySelector('.hola-date-2');
        if (holaElement) {
            const focusZoneElement = holaElement.querySelector('.ms-FocusZone');
            if (focusZoneElement) {
                const yearElement = focusZoneElement.querySelector('div[aria-label]');
                if (yearElement) {
                    if (yearObserverRef.current) {
                        yearObserverRef.current.disconnect();
                    }

                    yearObserverRef.current = new MutationObserver((mutationsList) => {
                        for (const mutation of mutationsList) {
                            if (mutation.type === 'childList' || mutation.type === 'characterData') {
                                const year = yearElement.getAttribute('aria-label');
                                if (year) {
                                    disableBlockedMonths(parseInt(year, 10));
                                }
                            }
                        }
                    });

                    const config = { childList: true, subtree: true, characterData: true };
                    yearObserverRef.current.observe(yearElement, config);

                    const year = yearElement.getAttribute('aria-label');
                    if (year) {
                        disableBlockedMonths(parseInt(year, 10));
                    }
                }
            }
        }
    };

    const disableBlockedMonths = (year: number) => {
        const holaElement = document.querySelector('.hola-date-2');
        if (holaElement) {
            const focusZoneElement = holaElement.querySelector('.ms-FocusZone');
            if (focusZoneElement) {
                const blockedMonthsForYear = blockedMonths[year] || [];
                blockedMonthsForYear.forEach(month => {
                    const monthButton = focusZoneElement.querySelector(`button[aria-label="${getMonthName(month-1)}"]`);
                    if (monthButton) {
                        monthButton.classList.add('disabled-month');
                    }
                });
            }
        }
    };

    const getMonthName = (month: number): string => {
        const monthNames = [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ];
        return monthNames[month];
    };

    useEffect(() => {
        if (isDatePickerOpen) {
            // Usar setTimeout para asegurarse de que el DOM esté completamente renderizado
            setTimeout(() => {
                observeYearChanges();
            }, 100);
        }

        return () => {
            if (yearObserverRef.current) {
                yearObserverRef.current.disconnect();
                yearObserverRef.current = null;
            }
        };
    }, [isDatePickerOpen]);

    const onClick = () => {
        setIsDatePickerOpen(true);

        // Esperar un momento para permitir que el calendario se renderice
        setTimeout(() => {
            observeYearChanges();
        }, 100); // Ajusta el tiempo según sea necesario
    };

    const handleSelectDate = (date: Date | null | undefined) => {
        setIsDatePickerOpen(false);
        onSelectDate(date); // Llama a la función que maneja el cambio de fecha

        // Desconectar el observador cuando se cierra el DatePicker
        if (yearObserverRef.current) {
            yearObserverRef.current.disconnect();
            yearObserverRef.current = null;
        }
    };

    return (
        <DatePicker
            strings={strings}
            label={label}
            value={value}
            formatDate={DateCustom.formatDateForDatePicker_MMYYYY}
            onSelectDate={handleSelectDate}
            className="hola-date"
            calendarProps={{ isDayPickerVisible: false, className: 'hola-date-2' }}
            minDate={minDate}
            onClick={onClick}
            onClickCapture={onClick}
        />
    );
};

export default CustomDatePicker;
